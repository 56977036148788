import React from "react";
import Logo from "../../assets/images/svg/logo.svg";
import { Link } from "react-router-dom";
import GoToPlatform from "../button/GoToPlatform";
import HelpMe from "../button/HelpMe";
import clsx from "clsx";

function Navbar2({ hamburgerMenuOpen, setHamburgerMenuOpen }) {
  const onClickHamburgerMenu = () => {
    setHamburgerMenuOpen(!hamburgerMenuOpen);
  };
  return (
    <div
      className={clsx(
        "main-top-section z_index_10 sticky-top sticky-top-navbar",
        hamburgerMenuOpen ? "mobile-view" : null
      )}
    >
      <nav className="navbar-wrapper d-flex py-2">
        <div
          id="overlay"
          className={clsx(hamburgerMenuOpen ? "overlay-active" : null)}
        >
          <div className="container custom_container">
            <div className="row justify-content-center">
              <div className="col-6">
                <div className="left d-flex justify-content-center text-center flex-column align-items-center">
                  <img src={Logo} alt="logo" />
                  <ul className="list-unstyled pt-4">
                    <li className="py-3 overflow-hidden">
                      <span className="d-inline-block">
                        <Link
                          to=""
                          className="overlay-links fs_sm fw-normal ff_bai text-white"
                          onClick={onClickHamburgerMenu}
                        >
                          Home
                        </Link>
                      </span>
                    </li>
                    <li className="py-3 overflow-hidden">
                      <span className="d-inline-block section-2">
                        <Link
                          to=""
                          className="overlay-links fs_sm fw-normal ff_bai text-white"
                        >
                          About
                        </Link>
                      </span>
                    </li>

                    <li className="py-3 overflow-hidden">
                      <span className="d-inline-block section-4">
                        <a
                          href="https://blog.eyrid.com/"
                          className="overlay-links fs_sm fw-normal ff_bai text-white"
                          onClick={onClickHamburgerMenu}
                        >
                          Blog
                        </a>
                      </span>
                    </li>
                    <li className="pt-4 d-flex gap-3">
                      <span>
                        <HelpMe extraClassName="text-nowrap" />
                      </span>
                      <span>
                        <GoToPlatform variant="Login" />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container custom_container ">
          <div className="nav-quantum d-flex justify-content-between align-items-center">
            <div>
              <a href="#" className="">
                <img src={Logo} alt="logo" />
              </a>
            </div>
            <div className="d-flex align-items-center">
              <div className="nav-items-wrap d-none d-lg-block">
                <ul className="nav-items-list list-unstyled align-items-center d-flex align-items-center mb-0">
                  <li className="me-4 ">
                    <a
                      href="#"
                      className="nav-links overlay-links fs_sm fw-bold ff_bai text-white"
                    >
                      Home
                    </a>
                  </li>
                  <li className=" me-4 ms-2">
                    <a
                      className="nav-links overlay-links fs_sm fw-normal ff_bai text-white"
                      href="#"
                    >
                      About
                    </a>
                  </li>

                  <li className=" me-4 ms-2">
                    <a
                      href="https://blog.eyrid.com"
                      className="nav-links overlay-links fs_sm fw-normal ff_bai text-white"
                    >
                      Blog
                    </a>
                  </li>
                  <li className=" mx-4">
                    <HelpMe />
                  </li>
                  <li>
                    <GoToPlatform variant="Login" />
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="main-menu"
              className={clsx(
                "hamburger-menu d-inline-block d-lg-none me-sm-2",
                hamburgerMenuOpen ? "animate" : null
              )}
              onClick={onClickHamburgerMenu}
            >
              <span className="first"></span>
              <span className="sec"></span>
              <span className="third"></span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar2;
