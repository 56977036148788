import React, { useEffect } from "react";
import { eyridStoriesData } from "../../../data/SuccessStoriesData";
import SuccessStoriesCarousel from "../../carousel/SuccessStoriesCarousel";
import LeftShadow2 from "../../../assets/images/svg/left_shadow2.svg";
import RightShadow from "../../../assets/images/png/right_shadow.png";
import SuccessStoriesDesktop from "./SuccessStoriesDesktop";

export const SuccessStories = () => {
  return (
    <section className="pt-5 mt-5 position-relative">
      <img
        src={LeftShadow2}
        alt="shadow"
        className="position-absolute start-0 index_minus fotr_shadow_1"
      />
      <img
        src={RightShadow}
        alt="shadow"
        className="position-absolute end-0 index_minus story_shadow_bottom"
      />
      <div className="container custom_container pt-lg-5">
        <div className="text-center">
          <h2
            className="fs_5xl fw-bold ff_bai text-white mb-4"
            data-aos="zoom-in-down"
            data-aos-duration="1200"
          >
            Stories from across the industries
          </h2>
        </div>

        <SuccessStoriesCarousel data={eyridStoriesData} />
        <SuccessStoriesDesktop data={eyridStoriesData} />
      </div>
    </section>
  );
};

export default SuccessStories;
