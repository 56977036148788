import React from "react";
import { Link } from "react-router-dom";

export const PrivacyTOSNav = () => (
  <ul class="nav">
    <li class="nav-item">
      <h4>
        <Link to="/legal/terms-of-service" class="nav-link">
          Terms of Service
        </Link>
      </h4>
    </li>
    <li class="nav-item">
      <h4>
        <Link class="nav-link" to="/legal/privacy-policy">
          Privacy Policy
        </Link>
      </h4>
    </li>
  </ul>
);
