import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { Offcanvas, Container, Navbar } from "react-bootstrap";
import Logo from "../../assets/images/logos/EYRID.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config/config"


const Navbar2 = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [affiliatePath, setAffiliatePath] = useState("");

  // cleans other url querys expect affiliate ones
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const medium = urlParams.get('utm_medium'); 
    const source = urlParams.get('utm_source');
    const campaign = urlParams.get('utm_campaign');
    let affiliate = "?";
    if (medium) {
      affiliate += `utm_medium=${medium}`;
      if (source) {
        affiliate += `&utm_source=${source}`;
      }
      if (campaign) {
        affiliate += `&utm_campaign=${campaign}`;
      }
    }
    setAffiliatePath(affiliate);
}, []);

  return (
    <NavBar
      collapseOnSelect
      expand="lg"
      sticky="top"
      // variant="light"
      // bg="dark"
      className="navbar d-flex justify-content-center text-center"
    >
      <Container>
        <NavBar.Brand className="ml-5">
          <Link to="/">
            <img
              src={Logo}
              width="90"
              alt="Abliminal Logo"
              style={{ transform: "scale(1.35)" }}
            />
          </Link>
        </NavBar.Brand>
        <div />
        
        <div class="hs-help-button">
        <a href={config.platform_url + "/login" + affiliatePath} className="btn-navbar">
            Login
          </a>

              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/19596935/1c813372-847c-4489-ab4a-280507bd22c1"
                target="_blank"
                rel="noopener"
                className="btn-navbar"
              >
                Help Me!
              </a>
        </div>
        <MenuButton
          aria-controls="offcanvasNavbar"
          onClick={handleShow}
          icon={faBars}
        ></MenuButton>{" "}
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Link to="/">
              <img
                src={Logo}
                width="90"
                alt="Abliminal Logo"
                style={{ transform: "scale(1.35)", padding: ".3rem" }}
              />
            </Link>
            {/* <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <NavLink href="/">Home</NavLink>
              <NavLink href="/learn-pb">Learn Policy Building</NavLink>
              <NavLink href="/glossary">Glossary</NavLink>
              <NavLink href="https://services.eyrid.com/ ">Services</NavLink>
              <NavLink href="https://blog.eyrid.com/">Blog</NavLink>
              {/* <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </NavBar>
  );
};

export default Navbar2;

const NavBar = styled(Navbar)`
  // background: rgba(255, 255, 255, 0.7) !important;
  background: #000e22;
  // background: white;
  opacity: 1;
  // backdrop-filter: blur(12px);
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
`;

const MenuButton = styled(FontAwesomeIcon)`
  color: white;
  transform: scale(1.5);
  cursor: pointer;
  margin: 1.2rem;

  &:hover {
    color: #c4c4c4;
  }
`;

const NavLink = styled(Nav.Link)`
  color: black;
  // font-weight: bold;
  font-family: "Poppins", sans-serif;

  &:hover {
    color: grey;
  }
`;
