import React, { useState } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Landing from "../pages/Landing";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";
import AboutPB from "../pages/AboutPB";
import Glossary from "../pages/Glossary";
import ScrollToTop from "../ScrollToTop";
import Navbar2 from "../components/navigation/Navbar2";

const Routes = () => {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  return (
    <BrowserRouter>
      <Navbar2
        hamburgerMenuOpen={hamburgerMenuOpen}
        setHamburgerMenuOpen={setHamburgerMenuOpen}
      />
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Landing hamburgerMenuOpen={hamburgerMenuOpen} />}
        />
        <Route path="/legal/privacy-policy" component={PrivacyPolicy} />
        <Route path="/legal/terms-of-service" component={TermsOfService} />
        <Route exact path="/learn-pb" component={AboutPB} />
        <Route exact path="/glossary" component={Glossary} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
