/* ============ PRELOADER START =================== */

var body = document.querySelector("body");
var html = document.querySelector("html");
document.body.classList.add("overflow-hidden");

setTimeout(() => {
    var preloader = document.getElementById("loading");
    preloader.style.display = "none";
    document.body.classList.remove("overflow-hidden");
    $("#main").addClass("vh-100");
}, 2000);