import React from "react";
import clsx from "clsx";

function HelpMe({ extraClassName = null }) {
  return (
    <a
      href="https://cta-redirect.hubspot.com/cta/redirect/19596935/1c813372-847c-4489-ab4a-280507bd22c1"
      target="_blank"
      rel="noopener"
      className={clsx(
        "help_btn fs_sm fw-normal ff_bai text-white",
        extraClassName
      )}
    >
      Help Me!
    </a>
  );
}

export default HelpMe;
