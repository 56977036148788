import React from "react";
import Stories from "../../../assets/images/svg/stories.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SuccessStoriesDesktop = ({ data }) => {
  return (
    <div className="d-none d-lg-block">
      <div className="row justify-content-center pt-3">
        {data.map((story, index) => {
          return (
            <div className="col-lg-6 col-xl-5" key={index}>
              <div className="stories_box_hover">
                <table>
                  <tbody>
                    <tr>
                      <th>
                        <FontAwesomeIcon icon={story.icon} />
                      </th>
                    </tr>
                    <tr className="mb-2 pt-1">
                      <th className="fs_sm fw-semibold pb-2">Client</th>
                      <td>{story.industry}</td>
                    </tr>
                    <tr className="vertical mb-2">
                      <th className="fs_sm fw-semibold pb-2">Problem</th>
                      <td>{story.problem}</td>
                    </tr>
                    <tr>
                      <th className="fs_sm fw-semibold">Outcome</th>
                      <td>{story.outcome}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SuccessStoriesDesktop;
