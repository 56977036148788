import React, { Component } from "react";
import "./carousel.css";

import Stories from "../../assets/images/svg/stories.svg";
import Stories2 from "../../assets/images/svg/stories2.svg";

const SuccessStoriesCarousel = ({ data }) => {
  return (
    <div className="d-lg-none">
      <div className="row justify-content-center pt-3 stories_slider">
        {data.map((story, index) => {
          return (
            <div
              className="mx-3"
              data-aos="zoom-in"
              data-aos-duration="1200"
              key={index}
            >
              <div className="stories_box">
                <table>
                  <tbody>
                    <tr>
                      <th>
                        <img src={Stories2} alt="stories" className="mb-3" />
                      </th>
                    </tr>
                    <tr className="mb-2 pt-1">
                      <th className="fs_sm fw-semibold">Client</th>
                      <td className="fw-normal fs_sm opacity_09">
                        Financial Services
                      </td>
                    </tr>
                    <tr className="vertical mb-2">
                      <th className="fs_sm fw-semibold">Problem</th>
                      <td className="fw-normal fs_sm opacity_09">
                        Legacy privilege system was difficult to maintain.
                      </td>
                    </tr>
                    <tr>
                      <th className="fs_sm fw-semibold">Outcome</th>
                      <td className="fw-normal fs_sm opacity_09">
                        Needs assessment and migration to new solution. custom
                        connector development. capability augmentation.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SuccessStoriesCarousel;
