const tos = [
    {
      section: "Introduction",
      content:
        "EyrID Inc. is in the business of development, supply and operation of products and services related to web-based software development; and WHEREAS, this Agreement contains EyrID Inc. terms of engagement; NOW THEREFORE, in consideration of the mutual covenants and agreements herein contained, the parties hereto, intending to be legally bound, agree as follows: ",
    },
    {
      section: "Services Provided",
      content:
        "EyrID Inc. will provide services to the User according to the enclosed order form. ",
    },
    {
      section: "Payment Options and Billing Arrangement",
      content: `EyrID Free Subscription  
    
        By Signing up for the free subscription to EyrID, and/or using the software encompassed within it, the USER agrees that you: (I)) have read all the terms and conditions contained in this agreement; (II) understand all the terms and conditions of this agreement; (III) accept and agree to be legally bound by the terms and conditions of this agreement.  
        
        If you do not agree with the terms and conditions of this agreement, do not sign up for a free subscription to EyrID. If you are using the software and do not agree with the terms and conditions, you must immediately discontinue use of the software and contact support@EyrID.com to terminate your account.  
        
        EyrID Paid Subscriptions  
        
        By Signing up for a paid subscription to EyrID, and/or using the software encompassed within it, the USER agrees that you: (I)) have read all the terms and conditions contained in this agreement; (II) understand all the terms and conditions of this agreement; (III) accept and agree to be legally bound by the terms and conditions of this agreement; (IV) agree to the payment terms as detailed in the invoice.  
        
        If you do not agree with the terms and conditions of this agreement, do not sign up and agree with the terms and conditions of this agreement. If you are using the software and do not agree with the terms and conditions, you must immediately discontinue use of the software and contact support@EyrID.com to terminate your account.  `,
    },
    {
      section: "Grant and Scope of This Agreement",
      content: `In consideration of the User agreeing, through the use of the software, to abide by the terms of this service agreement, EyrID Inc. hereby grants to you a non-exclusive, non-transferable use of this software on the terms of this agreement. `,
    },
    {
      section: "Intellectual and Property Rights",
      content: `The User acknowledges that all intellectual property rights in the software throughout the world belong to EyrID Inc., and that the User has no rights in, or to, the software other than the right to use the software in accordance with the terms of this agreement. `,
    },
    {
      section: "Non-Payment of Paid Subscription Plans",
      content: `If the User does not pay an invoice sent by EyrID Inc. or fails to comply with a request for payment within 30 days after the invoice is sent or the request is made; upon notification to the User, EyrID Inc. may immediately limit access to the User’s account until a payment arrangement has been made to bring the account to balance. Invoices not paid within 30 days will be subject to interest charges as set forth in the invoice. `,
    },
    {
      section: "Termination By User",
      content: `The User may terminate this agreement if EyrID Inc. fails to reasonably provide the software, modules and support as outlined in this agreement. `,
    },
    {
      section: "Termination By EyrID Inc.",
      content: `EyrID Inc. may terminate this agreement and stop providing service to the User if the User does not comply with this Agreement. `,
    },
    {
      section: "Rights on Termination",
      type: "list",
      content: [
        {
          listHeader: "Upon termination of the Agreement for any reason: ",
          listContent: {
            points: [
              `All rights granted to the User under this agreement shall cease; `,
              `The User must cease all activities authorized by this agreement `,
            ],
          },
        },
      ],
    },
    {
      section: "User Information (Data)",
      type: "list",
      content: [
        {
          listHeader: "Ownership",
          listContent: {
            points: [
              `All information entered into EyrID web-based software will be considered the property of the User. EyrID Inc. will not access or use data entered into the EyrID application for any other purpose than those expressed in this agreement. `,
            ],
          },
        },
        {
          listHeader: "Security",
          listContent: {
            points: [
              `EyrID Inc. will make every reasonable effort to store, protect and back up the Information (Data) entered into the EyrID application by the representatives of the User. This information will be retained throughout your subscription. If you choose to terminate your subscription your data will be kept for one year at which point it will be destroyed. Neither EyrID nor its affiliates will make any unauthorized use of the Data or disclose, in whole or in part, any part of the Data to any individual or entity, except to those of EyrID employees or consultants who require access and agree to comply with the use and nondisclosure restrictions applicable to the Data under this Agreement. 
    
                The User will make every reasonable effort to protect its own account information (usernames and passwords) from misuse by company staff. The User will also make reasonable effort to ensure that account information does not fall into the hands of individuals with intent to harm either the User or EyrID Inc. The Privacy Policy of EyrID Inc., as amended from time to time, is hereby incorporated into, and forms part of this Agreement. `,
            ],
          },
        },
      ],
    },
    {
      section: "Term",
      type: "list",
      content: [
        {
          listHeader: "Free Subscription",
          listContent: {
            points: [
              `The term of the Free EyrID Subscription shall be at the discretion of EyrID Inc. EyrID Inc. will give the User written 60 days notice of any change to the Free Subscription model. `,
            ],
          },
        },
        {
          listHeader: "EyrID Plus and Premium Subscription",
          listContent: {
            points: [
              `The term of each EyrID Plus and Premium subscription shall be as specified in the Subscription Order Form. Except as otherwise specified in the Subscription Order Form, subscriptions will automatically renew for additional periods equal to the expiring subscription term or one year (whichever is shorter), unless either party gives the other notice of non-renewal at least 30 days before the end of the relevant subscription term. The per-unit pricing during any automatic renewal term will be the same as that during the immediately prior term unless EyrID Inc. have given the User written notice of a pricing increase at least 60 days before the end of that prior term, in which case the pricing increase will be effective upon renewal and thereafter. `,
            ],
          },
        },
      ],
    },
    {
      section: "Changes to Terms of Service and Usage",
      content: `EyrID Inc. will provide the User with 60 days notice of any changes to the Terms of Service. `,
    },
    {
      section: "Limitation of Liability",
      content: `EyrID Inc. shall not be liable for lost profits, loss of goodwill, or any special, indirect, consequential or incidental damages, however caused and on any theory of liability, arising in any way out of this agreement. Subject to this article 12, the total liability of EyrID Inc. in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of the Agreement shall be limited to the subscription fee. `,
    },
    {
      section: "Force Majeure",
      content: `EyrID Inc. reserves the right to defer the date of delivery or to cancel the contract if it is prevented from or delayed in the carrying on of its business due to circumstances beyond the reasonable control of EyrID Inc. including, without limitation, acts of God, governmental actions, war or national emergency, acts of terrorism, protests, riot, civil commotion, fire, explosion, flood, epidemic, lock-outs, strikes or other labour disputes (whether or not relating to either party’s workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, provided that, if the event in question continues for a continuous period in excess of 90 days, the User shall be entitled to give notice in writing to EyrID Inc. to terminate the Agreement. In the event of cancellation for reason of force majeure, EyrID Inc. agrees to refund User with any monies paid in advance for services not yet rendered. `,
    },
  ];
  
  module.exports = {
    tos,
  };
  