import React from "react";
import BgShadow from "../../../assets/images/png/bg_shadow.png";
import BoostImgs from "../../../assets/images/svg/boost_imgs.svg";
import BoostImgs2 from "../../../assets/images/svg/boost_imgs2.svg";
import BoostImgs3 from "../../../assets/images/svg/boost_imgs3.svg";
import BoostImgs4 from "../../../assets/images/svg/boost_imgs4.svg";
import Prev from "../../../assets/images/svg/prev.svg";
import Next from "../../../assets/images/svg/next.svg";
import Slider from "react-slick";
import clsx from "clsx";

const dataElements = [
  { text: "Identity Unification and Federation", image: BoostImgs },
  { text: "Privileged and Secret Management", image: BoostImgs2 },
  { text: "Capability Augmentation", image: BoostImgs3 },
  { text: "Test Lab Deployment", image: BoostImgs4 },
  { text: "DevOps Security", image: BoostImgs },
  { text: "Application Patterns", image: BoostImgs2 },
  { text: "Access Governance", image: BoostImgs3 },
  { text: "Connectors", image: BoostImgs2 },
  { text: "Virtual Directory Services", image: BoostImgs4 },
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={Next}
      alt="next"
      className="slick-arrow slick-next"
      onClick={onClick}
      style={{ ...style }}
    />
  );
}
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={Prev}
      alt="prev"
      className="slick-arrow slick-prev"
      onClick={onClick}
      style={{ ...style }}
    />
  );
}

function GenericFeatures() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          cssEase: "linear",
          autoplaySpeed: 1,
          speed: 4000,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          autoplay: true,
          cssEase: "linear",
          autoplaySpeed: 1,
          speed: 4000,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section id="blog" className="mt-0 mt-md-5 pt-5 boost position-relative">
      <img
        src={BgShadow}
        alt="bg_shadow"
        className="w-100 boost_sec_shadow position-absolute index_minus d-none d-lg-block"
      />
      <div className="container custom_container">
        <div className="row justify-content-center">
          <div
            className="col-md-10 col-lg-9 col-xl-7"
            data-aos="zoom-in"
            data-aos-duration="1200"
          >
            <h2 className="text-white text-center fw-bold fs_5xl ff_bai">
              Boost your existing system by adopting EyrID's features
            </h2>
          </div>
        </div>

        <Slider
          {...sliderSettings}
          className="mt-4"
          data-aos="zoom-in"
          data-aos-duration="1200"
        >
          {dataElements.map((data, index) => {
            return (
              <div
                className={clsx(
                  "text-center mx-3",
                  index % 2 === 0 ? "boost_box" : "boost_box2"
                )}
                data-aos="zoom-in"
                data-aos-duration="1200"
                key={index}
              >
                <img src={data.image} alt="boost_imgs" className="mx-auto" />
                <p className="fs_lg fw-semibold ff_bai text-white mb-0 mt-4">
                  {data.text}
                </p>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}

export default GenericFeatures;
