import React from "react";
import { Link } from "react-router-dom";

function LearnMore() {
  return (
    <Link
      to="/learn-pb/"
      className="btn_login fs_sm fw-normal ff_bai
                                  text-white"
    >
      Learn More
    </Link>
  );
}

export default LearnMore;
