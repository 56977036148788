import React from "react";
import Easy1 from "../../assets/images/svg/easy_1.svg";
import Easy2 from "../../assets/images/svg/easy_2.svg";
import Easy3 from "../../assets/images/svg/easy_3.svg";
import Easy4 from "../../assets/images/svg/easy_4.svg";
import Easy5 from "../../assets/images/svg/easy_5.svg";
import Easy6 from "../../assets/images/svg/easy_6.svg";
import Easy7 from "../../assets/images/svg/easy_7.svg";
import Easy8 from "../../assets/images/svg/easy_8.svg";
import BgShadow from "../../assets/images/png/bg_shadow.png";
import Slider from "react-slick";
import clsx from "clsx";

const dataElements = [
  {
    name: "Best Practices Not Being Shared Well",
    image: Easy1,
    classExtras: "",
  },
  {
    name: "Mid-size Companies Struggle with IAM Programs",
    image: Easy2,
    classExtras: "",
  },
  {
    name: "Clients Unsure About Next Steps in Program",
    image: Easy3,
    classExtras: "mt-md-4 mt-lg-0",
  },
  {
    name: "Risk in Large Scale Implementation",
    image: Easy4,
    classExtras: "mt-md-4 mt-xl-0 pt-xl-0 pt-lg-3",
  },
  { name: "Resource Intensive", image: Easy5, classExtras: "mt-md-4 pt-lg-3" },
  {
    name: "Solutions poorly integrated",
    image: Easy6,
    classExtras: "mt-md-4 pt-lg-3",
  },
  {
    name: "Lot of different option in market",
    image: Easy7,
    classExtras: "mt-md-4 pt-lg-3",
  },
  {
    name: "Lack of authoritative source",
    image: Easy8,
    classExtras: "mt-md-4 pt-lg-3",
  },
];

export default function IdentityMadeEasy() {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    cssEase: "linear",
    autoplaySpeed: 1,
    speed: 4000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      id="about"
      className="mt-md-5 py-4 pt-lg-0 position-relative z_index_5"
    >
      <img
        src={BgShadow}
        alt="bg_shadow"
        className="w-100 secnd_sec_shadow position-absolute index_minus"
      />
      <div className="container custom_container">
        <div className="text-center">
          <h2 className="text-white fw-bold fs_5xl ff_bai">
            Identity made Easy
          </h2>
          <p className="text-white fw-normal fs_sm ff_bai opacity_09 px-5">
            Enabling a Greater Employee and customer experience.{" "}
          </p>
        </div>

        <div className="row d-md-none mt-4 pt-3 justify-content-center">
          <Slider {...sliderSettings}>
            {dataElements.map((data, index) => {
              return (
                <div className="col-md-6 col-lg-4 col-xl-3 mx-2" key={index}>
                  <div data-aos="zoom-in" data-aos-duration="1200">
                    <div className="easy_box text-center">
                      <img
                        src={data.image}
                        alt="easy_1"
                        className="mx-auto mt-3"
                      />
                      <p className="mt-4 mb-0 fs_lg ff_bai fw-bold text-white">
                        {data.name}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="d-none d-md-block">
          <div className="row  m-2 pt-3 justify-content-center ">
            {dataElements.map((data, index) => {
              return (
                <div
                  className={clsx(
                    "col-md-5 col-lg-4 col-xl-3",
                    data.classExtras
                  )}
                  key={index}
                >
                  <div
                    className="easy_box text-center"
                    data-aos="zoom-in"
                    data-aos-duration="1200"
                  >
                    <img src={data.image} alt="easy" />
                    <p className="mt-4 mb-0 fs_lg ff_bai fw-bold text-white">
                      {data.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
