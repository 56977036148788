import React from "react";
import Benefits from "../components/content/Landing/Benefits";
import GenericFeatures from "../components/content/Landing/GenericFeatures";
import SuccessStories from "../components/content/Landing/SuccessStories";
import Layout from "./layout/Layout";
import Header from "../components/header/Header";
import IdentityMadeEasy from "../components/section/IdentityMadeEasy";
import Journey from "../components/content/Landing/Journey";
import PricingPlans from "../components/content/Landing/PricingPlans";
import Collaborators from "../components/content/Landing/Collaborators";
import Faq from "../components/content/Landing/Faq";

const Landing = () => {
  return (
    <Layout
      hasFooter
      pageTitle={`EyrID: Unified Identity & Access Management`}
      page="eyrid"
    >
      <Header />
      <IdentityMadeEasy />
      <Benefits />
      <GenericFeatures />
      <Journey />
      <PricingPlans />
      <SuccessStories />
      <Collaborators />
      <Faq />
    </Layout>
  );
};

export default Landing;
