import React from "react";
import Navbar2 from "../navigation/Navbar2";
import Carousel2 from "../carousel/Carousel2";

function Header() {
  return (
    <header className="hero_bg pb-5">
      <Carousel2 />
    </header>
  );
}

export default Header;
