import React from "react";
import { Link } from "react-router-dom";
import LinkedinIcon from "../../assets/images/logos/linkedin-brands.svg";
import "./footer.css";
import Logo from "../../assets/images/svg/logo.svg";
import TwitterLogo from "../../assets/images/svg/twitter.svg";
import InstagramLogo from "../../assets/images/svg/instagram.svg";
import TelegramLogo from "../../assets/images/svg/telegram.svg";
import LinkedInLogo from "../../assets/images/svg/linkdin.svg";

function Footer() {
  let year = new Date().getFullYear();

  const links = [
    // {
    //   category_name: "Useful Links",
    //   items: [
    //     { title: "Documentation", link: "/underconstruction" },
    //     { title: "Terms of Service", link: "/underconstruction" },
    //     { title: "Privacy Policy", link: "/underconstruction" },
    //   ],
    // },
    // {
    //     category_name: "Platforms",
    //     items: [
    //         { title: "Apthenal", link: "/apthenal" },
    //         { title: "EyrID", link: "/eyrid" },
    //     ],
    // },
    {
      category_name: "Legal",
      items: [
        { title: "Terms of Service", link: "/legal/terms-of-service" },
        { title: "Privacy Policy", link: "/legal/privacy-policy" },
      ],
    },
    // {
    //   category_name: "Company",
    //   items: [
    //     // { title: "Contact Us", link: "contact" },
    //     { title: "Careers", link: "/underconstruction" },
    //   ],
    // },
  ];

  return (
    <footer className="pt-5 pt-lg-0 position-relative z_index_5 footer_blur footer_blur2">
      <div className="container custom_container mb-lg-5 pb-2 pb-sm-5">
        <div className="row justify-content-center mb-lg-4">
          <div className=" col-12 col-md-6 z_index_5">
            <div className="">
              <ul className="ps-0">
                <li>
                  <a
                    href=""
                    className="text-white fs_sm fw-semibold opacity_09 "
                  >
                    <img src={Logo} alt="logo" className="cursor_pointer" />
                  </a>
                </li>
                <li>
                  <a href="" className="text-white fs_sm fw-normal opacity_08 ">
                    <p className="text-white fs_sm fw-normal opacity_08 m_w307 cursor_pointer"></p>
                  </a>
                </li>
              </ul>
              <div className=" pt-3">
                <a href="https://twitter.com/">
                  <img
                    src={TwitterLogo}
                    alt="twitter logo"
                    className="icon_hov"
                  />
                </a>
                <a href="https://www.instagram.com/">
                  {" "}
                  <img
                    src={InstagramLogo}
                    alt="instagram logo"
                    className="px-2 icon_hov"
                  />
                </a>
                <a href="https://telegram.org/">
                  {" "}
                  <img
                    src={TelegramLogo}
                    alt="telegram logo"
                    className="icon_hov"
                  />{" "}
                </a>
                <a href="https://www.linkedin.com">
                  {" "}
                  <img
                    src={LinkedInLogo}
                    alt="logo"
                    className="ps-2 icon_hov"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3 mt-5 mt-sm-4 z_index_5">
            {links.map((item, index) => {
              return (
                <ul className="ps-0 ps-lg-5 " key={index}>
                  <li>{item.category_name}</li>
                  {item.items.map((item, index) => {
                    return (
                      <li className="mt-2" key={index}>
                        <Link to={item.link}>{item.title}</Link>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
          <div className=" col-6 col-md-3 pe-xl-5 mt-5 mt-sm-4 z_index_5">
            <ul className="ps-0 pe-lg-4">
              <li>
                <a
                  href=""
                  className="text-white fs_sm fw-semibold opacity_09 pb-1"
                >
                  Main Office
                </a>
              </li>
              <li className="mt-2">
                <a href="" className="text-white fs_sm fw-normal opacity_08 ">
                  First Canadian Place 100 King Street W, Toronto, Ontario M5X
                  1A9 Canada
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div className="text-white fs_sm fw-normal opacity_08 text-center pt-2 pb-4">
        Copyright @ {year}{" "}
      </div>
    </footer>
  );
}

export default Footer;
