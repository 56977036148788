import React, { useEffect } from "react";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Routes from "./routes/Routes";
import Navbar2 from "./components/navigation/Navbar2";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Routes />
    </>
  );
};

export default App;
