import React from "react";
import Layout from "./layout/Layout";
import Secure from "../assets/images/svg/pb-images/secure.svg";
import Research from "../assets/images/svg/pb-images/research.svg";
import Book from "../assets/images/svg/pb-images/book.svg";
import { ArrowBack } from "@material-ui/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";

// import MainPB from "../components/content/MainPB";

const AboutPB = () => {
  return (
    <Layout
      hasFooter
      pageTitle={`EyrID: Unified Identity & Access Management`}
      page="eyrid"
    >
      <StyledSection>
        <StyledDiv>
          <Link to="/">
            <ArrowBack fontSize="large" />
          </Link>
          <br />
          <TextBox>
            <h1 className={"heading-text"}>IAM & POLICY BUILDING BASICS</h1>
            <h4>
              Get to know the basics and understand the importance of creating
              policies
            </h4>
          </TextBox>
        </StyledDiv>
        <svg
          width="100%"
          height="10%"
          viewBox="0 0 1920 228"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1920 193.643L1813.33 187.953C1706.67 181.833 1493.33 171.097 1280 130.62C1066.67 90.5734 853.333 21.8601 640 4.68182C426.667 -12.4965 213.333 21.8601 106.667 39.0385L-5.72205e-05 56.2168V228H106.667C213.333 228 426.667 228 640 228C853.333 228 1066.67 228 1280 228C1493.33 228 1706.67 228 1813.33 228H1920V193.643Z"
            fill="white"
          />
        </svg>
      </StyledSection>

      <section>
        <Block>
          <TextBox>
            <h2>What is Identity and Access Management?</h2>
            <p>
              Identity and Access Management (IAM) are the processes that enable
              an organization to administer and manage resources. These
              processes ensure that the right people have access to the right
              resources at the right time.
            </p>
            <br />
            <p>
              IAM covers everything from authorizing access to password strength
              to provisioning and deprovisioning users. Managing your
              organization’s identity and access should be easy, time-sensitive,
              and should not put too much of a burden on your team’s resources.
            </p>
            <br />
            <p>
              This is where a policy comes in. It is significantly easier to
              define, manage, and control your organization’s identity and
              access management when you have a policy. A policy sets out the
              parameters for you to follow, and if followed correctly, is one of
              the key steps that you can take to protect your business and
              assets.
            </p>
          </TextBox>

          <img src={Secure}></img>
        </Block>
      </section>

      <section>
        <Block>
          <img src={Research}></img>
          <TextBox>
            <h2>Why do I need an Identity and Access Management policy? </h2>
            <p>
              Policies in the workplace are essential. For example, most
              organizations have an annual leave policy. In this document, it
              will detail how many annual leave days you can take, how you can
              book these days and if you can carry these over. Without this
              policy, employee productivity may suffer, and you could break the
              law.
            </p>
            <br />
            <p>
              Introducing and following policies solves all these issues. In one
              document it sets clear expectations for employees, creates
              consistency throughout the organization, reduces risk, adheres to
              legal regulations, and improves employee productivity. With the
              rise of employees working from home, an increasing number of
              businesses are pivoting to working remotely. It's more important
              than ever to have a robust IAM policy.
            </p>
          </TextBox>
        </Block>
      </section>

      <section>
        <Block>
          <TextBox>
            <h2>What is a policy builder?</h2>
            <p>
              With our wealth of experience writing IAM policies for our
              clients, we realized how repetitive and time-consuming the process
              is, so we’ve done all the hard work for you! We have created a
              gold-standard template to save you time.
            </p>
            <br />
            <p>
              {" "}
              Our policy builder is easy to use and takes less than ten minutes
              to complete. All you need to do is answer a few questions voila
              your own unique, personalized identity and access management
              policy will be generated.{" "}
              <a href="#">Click here to get started</a>
            </p>
            <br />
            <br />
            <p>
              If you’re still stuck or have any questions, please reach out to
              talk to one of our experts. We are available through live chat, on
              LinkedIn{" "}
              <a target="_blank" href="https://ca.linkedin.com/company/eyrid">
                @EyrID
              </a>
              , or through our mascot{" "}
              <a
                target="_blank"
                href="https://ca.linkedin.com/in/eyrie-e-a23b06211"
              >
                @Eyrie E
              </a>
              , via email at{" "}
              <a href="mailto:eyrie@eyrid.com">eyrie@eyrid.com</a>.
            </p>
          </TextBox>
          <img src={Book}></img>
        </Block>
      </section>
    </Layout>
  );
};

export default AboutPB;

const StyledDiv = styled.div`
  // border: 1px solid red;
  display: flex;
  margin: 2rem;
  color: white;
  .heading-text {
    max-width: 25ch;
    font-size: 2rem !important;
    margin: 0;
    // margin-bottom: 2rem;
  }
  h1 {
    max-width: 15ch;
    // font-size: 1.3rem !important;
    padding: 2rem 0;
    margin: 0;
  }
  a {
    color: white;
    &:hover {
      color: black;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledSection = styled.section`
  background: #505b97;
  padding: 0 !important;
  // padding-top: 6rem !important;
  color: white;
`;

const Wave = styled.div`
  background: white;
  height: 10vh;
  width: 100vw;
  clip-path: polygon(
    100% 0%,
    0% 0%,
    0% 65%,
    1% 64.95%,
    2% 64.8%,
    3% 64.6%,
    4% 64.3%,
    5% 63.9%,
    6% 63.45%,
    7% 62.9%,
    8% 62.25%,
    9% 61.55%,
    10% 60.8%,
    11% 59.95%,
    12% 59.05%,
    13% 58.1%,
    14% 57.1%,
    15% 56.05%,
    16% 55%,
    17% 53.9%,
    18% 52.8%,
    19% 51.65%,
    20% 50.5%,
    21% 49.35%,
    22% 48.2%,
    23% 47.05%,
    24% 45.9%,
    25% 44.8%,
    26% 43.75%,
    27% 42.75%,
    28% 41.75%,
    29% 40.8%,
    30% 39.9%,
    31% 39.1%,
    32% 38.35%,
    33% 37.65%,
    34% 37.05%,
    35% 36.5%,
    36% 36.05%,
    37% 35.65%,
    38% 35.35%,
    39% 35.15%,
    40% 35.05%,
    41% 35%,
    42% 35.05%,
    43% 35.2%,
    44% 35.45%,
    45% 35.75%,
    46% 36.15%,
    47% 36.65%,
    48% 37.2%,
    49% 37.85%,
    50% 38.55%,
    51% 39.35%,
    52% 40.2%,
    53% 41.1%,
    54% 42.05%,
    55% 43.05%,
    56% 44.1%,
    57% 45.15%,
    58% 46.3%,
    59% 47.4%,
    60% 48.55%,
    61% 49.7%,
    62% 50.85%,
    63% 52%,
    64% 53.15%,
    65% 54.25%,
    66% 55.35%,
    67% 56.4%,
    68% 57.45%,
    69% 58.4%,
    70% 59.35%,
    71% 60.2%,
    72% 61.05%,
    73% 61.8%,
    74% 62.45%,
    75% 63.05%,
    76% 63.6%,
    77% 64.05%,
    78% 64.4%,
    79% 64.7%,
    80% 64.85%,
    81% 65%,
    82% 65%,
    83% 64.9%,
    84% 64.75%,
    85% 64.5%,
    86% 64.2%,
    87% 63.75%,
    88% 63.25%,
    89% 62.7%,
    90% 62.05%,
    91% 61.3%,
    92% 60.5%,
    93% 59.65%,
    94% 58.75%,
    95% 57.8%,
    96% 56.8%,
    97% 55.75%,
    98% 54.65%,
    99% 53.55%,
    100% 52.4%
  );
`;

const TextBox = styled.div`
  // border: 1px solid red;
  // margin-top: 2rem;
  margin: 2rem;
  .heading-text {
    max-width: 25ch;
    font-size: 2rem !important;
    margin: 0;
    // margin-bottom: 2rem;
  }
  h2 {
    max-width: 30ch;
    font-size: 2rem !important;
    padding: 2rem 0;
    margin: 0;
    // font-size: 1.3rem !important;
    // padding-bottom: 2rem;
  }
  p {
    max-width: 60ch;
  }
`;

const Block = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin: 1.2rem;
  //   background-color: red;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
