import React from "react";
import GoToPlatform from "../../button/GoToPlatform";

function PricingPlans() {
  const pricingPlans = [
    {
      name: "Free",
      description:
        "Get started on stage 1 of your IAM journey with up to 3 collaborators!",
      price: "Free for 30 days",
    },
    {
      name: "Basic",
      description:
        "Get started on stage 1 of your IAM journey with up to 3 collaborators!",
      price: "$9.99/month, billed annually",
    },
    {
      name: "Premium",
      description:
        "Includes process development and capabilities architecture!",
      price: "$74.99/month, billed annually",
    },
    {
      name: "Enterprise",
      description: "Help with strategy, budget, roadmap, and implementations!",
      price: "$174.99/month, billed annually",
    },
  ];

  return (
    <section class="pt-4 ">
      <div class="container custom_container mt-5">
        <div
          class="text-center"
          data-aos="zoom-in-down"
          data-aos-duration="1200"
        >
          <h2 class="fs_5xl fw-bold ff_bai text-white">
            We have the plan that’s right for you
          </h2>
          <p class="fw-normal ff_bai fs_sm opacity_09 text-white mb-4">
            {" "}
            EyrID can help you on your IAM Journey!
          </p>
        </div>
        <div class="d-none d-md-block">
          <div class="row justify-content-center mt-4">
            {pricingPlans.map((plan, index) => {
              return (
                <div
                  class="col-md-6 col-lg-5 col-xl-3"
                  data-aos="zoom-in"
                  data-aos-duration="1200"
                  key={index}
                >
                  <div class="plans_frame_box">
                    <p class="fw-bold fs_xl mb-2">{plan.name}</p>
                    <p class="fw-normal fs_sm opacity_07 mb-4">
                      {plan.description}
                    </p>

                    <p class="fw-normal fs_sm opacity_09 mb-0 pb-4">
                      {plan.price}
                    </p>
                    <ul class="ps-0">
                      <li class="mt-2">
                        <span class="d-inline-block">
                          <GoToPlatform variant="Get Plan" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <ul class="ps-0">
            <li class="mt-5 text-center">
              <span class="d-inline-block">
                <a
                  href="#"
                  class="btn_login fs_sm fw-normal ff_bai
                                                        text-white"
                >
                  Compare Plan
                </a>
              </span>
            </li>
          </ul>
        </div>

        <div class="d-md-none">
          <div class="row pt-2 justify-content-center mt-4 plans_slider">
            {pricingPlans.map((plan, index) => {
              return (
                <div class="col-md-6 col-lg-5 col-xl-3 mx-3" key={index}>
                  <div class="plans_frame_box">
                    <p class="fw-bold fs_xl mb-2">{plan.name}</p>
                    <p class="fw-normal fs_sm opacity_07 mb-4">
                      {plan.description}
                    </p>

                    <p class="fw-normal fs_sm opacity_09 mb-0 pb-4">
                      {plan.price}
                    </p>
                    <li class="mt-2">
                      <span class="d-inline-block">
                        <GoToPlatform variant="Get Plan" />
                      </span>
                    </li>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingPlans;
