import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import LearnMore from "../button/LearnMore";
import GoToPlatform from "../button/GoToPlatform";
import HeroJson from "../../assets/hero.json";

function Carousel2() {
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    speed: 2500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          fade: false,
        },
      },
    ],
  };

  return (
    <section
      id="home"
      className="min_vh_75 mt-5 mt-lg-0 d-flex align-items-center position-relative hero_blur"
    >
      <div className="container custom_container">
        <Slider {...sliderSettings} className="hero_fade">
          <div>
            <div className="row align-items-center justify-content-between justify-content-sm-center flex-column flex-lg-row">
              <div
                className="col-lg-6 col-xl-7"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="pe-lg-5">
                  <h1 className="text-white ff_bai fw-bold fs_6xl">
                    Create a Policy for your IAM program in seconds
                  </h1>
                  <p className="text-white ff_bai fw-normal opacity_09 fs_sm pt-1 pe-5 mb-0">
                    EyrID is launching its policy builder! A free tool that
                    makes building and managing IAM programs easier for everyone
                  </p>
                  <ul className="d-flex pt-4 mt-3 align-items-center ps-0">
                    <li className="me-3">
                      <span className="d-inline-block">
                        <LearnMore />
                      </span>
                    </li>
                    <GoToPlatform />
                  </ul>
                </div>
              </div>
              <div className=" col-sm-9 col-lg-6 col-xl-5 ">
                <Player
                  autoplay
                  loop
                  src={HeroJson}
                  className="mx-auto"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
          </div>

          <div>
            <div className="row align-items-center justify-content-between justify-content-sm-center flex-column flex-lg-row min_h_hero">
              <div className="col-lg-6 col-xl-7">
                <div className="pe-lg-5">
                  <h1 className="text-white ff_bai fw-bold fs_6xl pe-5 max_width_400">
                    Identity Made Easy
                  </h1>
                  <p className="text-white ff_bai fw-normal opacity_09 mb-0 pt-1 pe-5 fs_sm">
                    EyrID is an assessment tool that helps organizations{" "}
                    <span className="d-xl-block"></span>
                    highlight and action issues and gaps in their IAM program.
                  </p>
                  <ul className="d-flex pt-4 mt-3 align-items-center ps-0">
                    <li className="me-3">
                      <span className="d-inline-block">
                        <LearnMore />
                      </span>
                    </li>
                    <GoToPlatform />
                  </ul>
                </div>
              </div>
              <div className=" col-sm-9 col-lg-6 col-xl-5 ">
                <Player
                  autoplay
                  loop
                  src={HeroJson}
                  className="mx-auto"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Carousel2;
