import React from "react";

import { eyridCollaboratorsData } from "../../../data/LogoData";
// import gaEventHandler from "../../gaEventHandler";

import Slider from "react-slick";

const Collaborators = () => {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    swipeToSlide: true,
    // centerMode: true,
    centerPadding: "10px",
    draggable: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <section class="py-5 mt-5 position-relative overflow-hidden">
      <div class="container custom_container pt-xl-5 mt-lg-5">
        <div class="" data-aos="zoom-in-down" data-aos-duration="1500">
          <h2 class="fs_5xl fw-bold ff_bai text-white text-center mb-sm-5">
            {" "}
            Collaborators
          </h2>
        </div>
        <div class="d-none d-sm-block">
          <div class="row gap-lg-5 justify-content-around ">
            {eyridCollaboratorsData.map((item, index) => {
              return (
                <div
                  data-aos="zoom-in-down"
                  data-aos-duration="1200"
                  class="col-4 col-lg-3"
                  key={index}
                >
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img
                      src={item.img_src}
                      alt={item.company}
                      class="w-100 svg_hover"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-sm-none">
              <div class="hope_slider_top pt-4 pb-lg-4 ">
                <div class="text-scroll align-items-center">
                  <Slider {...settings} className="slider">
                    {eyridCollaboratorsData.map((item, index) => {
                      return (
                        <div class="custom_img_slider">
                          <div>
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={item.img_src}
                                alt={item.company}
                                class="opacity_08"
                              />
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collaborators;
