import React from "react";
import config from "../../config/config";
import clsx from "clsx";

function GoToPlatform({ variant = null }) {
  return (
    <a
      href={config.platform_url}
      className={clsx(
        " fs_sm fw-normal ff_bai text-white",
        variant === "Login"
          ? "btn_login"
          : variant === "Get Plan"
          ? "btn_login"
          : "help_btn"
      )}
    >
      {variant === "Login"
        ? "Login"
        : variant === "Get Plan"
        ? "Get Plan"
        : "Go To Platform"}
    </a>
  );
}

export default GoToPlatform;
