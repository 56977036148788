import React from "react";
import clsx from "clsx";

const faqs = [
  {
    question: "What is IAM?",
    answer: [
      "Identity and Access Management (IAM) are the processes that enable an organization to administer and manage resources. These processes ensure that the right people have access to the right resources at the right time.",
      "IAM covers everything from authorizing access to password strength to provisioning and deprovisioning users. Managing your organization’s identity and access should be easy, time-sensitive, and should not put too much of a burden on your team’s resources.",
    ],
  },
  {
    question: "Why do I need IAM?",
    answer: [
      "An IAM policy sets clear expectations for employees, creates consistency throughout the organization, reduces risk, adheres to legal regulations, and improves employee productivity. With the rise of employees working from home, an increasing number of businesses are pivoting to working remotely. It's more important than ever to have a robust IAM policy.",
    ],
  },
  {
    question: "What is policy builder?",
    answer: [
      "EyrID’s policy builder enables you to create your own, customizable identity and access management policy. Creating a policy is the first step in establishing an identity program in your organization. Our policy builder provides an interactive and easy user experience, meaning creating and managing policies has never been so easy. The result is a complete IAM policy.",
    ],
  },
  {
    question: "How do you utilize the policy builder?",
    answer: [
      "On the side navigation, locate ‘IAM Manager’ to find Policy Builder. Click to get started and follow the prompts to input necessary information according to your organization.",
      "If you need to leave the page and continue at another time, your previous inputs will be saved so that it won’t have to be re-entered.",
    ],
  },
];

function Faq() {
  return (
    <section className="pt-5 mt-lg-5 mb-md-5 position-relative faq_blur">
      <div className="container custom_container position-relative mt-lg-5 z_index_6">
        <div className="row justify-content-center">
          <div className="col-lg-10 z_index_5">
            <h2
              className="fs_5xl fw-bold ff_bai text-white text-center "
              data-aos="fade-down"
              data-aos-duration="1200"
            >
              Frequently Asked Questions
            </h2>
            <div className="pt-5 pb-0 mb-md-5">
              <div className="accordion" id="accordionExample">
                {faqs.map((faq, index) => {
                  return (
                    <div className="accordion-item mb-4" key={index}>
                      <h2
                        className="accordion-header"
                        id={`heading${index + 1}`}
                      >
                        <button
                          className={clsx(
                            "accordion-button fw-semibold fs_xl text-white",
                            index === 0 ? null : "collapsed"
                          )}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index + 1}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index + 1}`}
                        >
                          Q.{index + 1} {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index + 1}`}
                        className={clsx(
                          "accordion-collapse collapse",
                          index === 0 ? "show" : null
                        )}
                        aria-labelledby={`heading${index + 1}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body fw-normal fs_sm text-white opacity_09">
                          {faq.answer.map((answer, index) => {
                            return <p key={index}>{answer}</p>;
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
