import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";
//import "./assets/js/app.js";
import "./assets/js/preloder.js";

import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(
  <App />,

  document.getElementById("app")
);
