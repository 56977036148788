import React, { useEffect, useState } from "react";
import * as Scroll from "react-scroll";

import "../styles/main.css";

import { getPrivacyPolicy } from "../utils/api/legal";
import { Table } from "react-bootstrap";
import Layout from "./layout/Layout";
import { PrivacyTOSNav } from "../components/navigation/PrivacyTOSNav";

const PrivacyPolicy = () => {
  let Link = Scroll.Link;

  const [data, setData] = useState([]);

  useEffect(() => {
    const data = getPrivacyPolicy();
    data
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const HeaderLink = ({ dest, index }) => (
    <Link
      to={`${dest}`}
      spy={true}
      smooth={true}
      offset={-100}
      duration={300}
      className="headerLink"
    >
      <span className="headerLinkText">{`${index + 1}. ${dest}`}</span>
    </Link>
  );

  return (
    <Layout hasFooter pageTitle={`EyrID - Privacy Policy`}>
      <div className="container pt-5 mt-5">
        <h2>Privacy Policy</h2>

        <PrivacyTOSNav />

        <div className="toc">
          {data?.map((item, index) => {
            return (
              <h5>
                <HeaderLink dest={item.mainSection} index={index} />
              </h5>
            );
          })}
        </div>

        <div className="latestUpdate mt-5">
          {data?.map((item, index) => {
            const filtered = item.contents.filter((n) => n.type === "date");

            const lastUpdated = filtered?.map((m, i) => {
              return (
                <h4>
                  {m.placeholder}
                  {m.value}
                </h4>
              );
            });
            return lastUpdated;
          })}
        </div>

        <div className="privacy-section pt-5">
          {data?.map((mainItem, index) => {
            //  undefined (default) | list | subsection | subsectionList | nestedList | table | date | address | email

            const element = mainItem.contents.map((item, i) => {
              switch (item.type) {
                case "list":
                  return (
                    <div className="mainSection pt-4">
                      <h4 className="mainSectionHeader">
                        <span id={`${mainItem.mainSection}`}>{`${index + 1}. ${
                          mainItem.mainSection
                        }`}</span>
                      </h4>
                      {item.header}
                      <ul key={index}>
                        {item.contents.map((point, j) => {
                          return <li>{point}</li>;
                        })}
                      </ul>
                    </div>
                  );

                case "subsection":
                  return (
                    <>
                      <h5>{item.header}</h5>
                      <div key={i}>
                        {item.contents.map((content, j) => {
                          return <p>{content}</p>;
                        })}
                      </div>
                    </>
                  );

                case "subsectionList":
                  return (
                    <>
                      <h5>{item.header}</h5>
                      <p>{item.listPrompt}</p>
                      <ul key={i}>
                        {item.listContents.map((content, j) => {
                          return <li>{content}</li>;
                        })}
                      </ul>
                      {item.contents.map((content, k) => {
                        return <p>{content}</p>;
                      })}
                    </>
                  );

                case "nestedList":
                  return (
                    <div className="mainSection pt-4">
                      <h4 className="mainSectionHeader">
                        <span id={`${mainItem.mainSection}`}>{`${index + 1}. ${
                          mainItem.mainSection
                        }`}</span>
                      </h4>
                      <p>{item.listPrompt}</p>
                      {item.listContents.map((item, j) => {
                        return (
                          <>
                            <h5 className="ml-3">{item.section}</h5>
                            <p className="ml-3">{item.sectionPrompt}</p>
                            <ol type="i">
                              {item.contents.map((subItem, k) => {
                                return (
                                  <>
                                    <li className="ml-4 font-weight-bold">{`${subItem.header}`}</li>
                                    <p className="ml-5">
                                      {subItem.description}
                                    </p>
                                    <ul className="ml-5" key={k}>
                                      {subItem.subContents?.map((entry, l) => {
                                        return (
                                          <>
                                            {entry.href ? (
                                              <li>
                                                {`${entry.type} ${entry.value} - `}
                                                <a
                                                  href={entry.href}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {entry.hrefText}
                                                </a>
                                                {entry.optout
                                                  ? ` - ${entry.optout}`
                                                  : null}
                                              </li>
                                            ) : (
                                              <li>{`${entry.type} ${entry.value}`}</li>
                                            )}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </>
                                );
                              })}
                            </ol>
                          </>
                        );
                      })}
                    </div>
                  );

                case "table":
                  return (
                    <div className="mainSection pt-4">
                      <h4 className="mainSectionHeader">
                        <span id={`${mainItem.mainSection}`}>{`${index + 1}. ${
                          mainItem.mainSection
                        }`}</span>
                      </h4>
                      <Table striped borderless variant="dark">
                        <thead
                          className="row"
                          style={{
                            borderBottom: "3px dashed #c9c9c9",
                          }}
                        >
                          <th className="col-6">Term</th>
                          <th className="col-6">Definition</th>
                        </thead>
                        <tbody>
                          {item.contents.map((row, j) => {
                            return (
                              <tr className="row" key={j}>
                                <td className="col-6 font-weight-bold">
                                  {row.term}
                                </td>
                                <td className="col-6 ml-auto">{row.def}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  );

                case "date":
                  return (
                    <div>
                      {data?.map((item, index) => {
                        const filtered = item.contents.filter(
                          (n) => n.type === "date"
                        );

                        const lastUpdated = filtered?.map((m, i) => {
                          return (
                            <p>
                              {m.placeholder}
                              {m.value}
                            </p>
                          );
                        });
                        return lastUpdated;
                      })}
                    </div>
                  );

                case "address":
                  return (
                    <p className="ml-5">
                      {item?.values.map((addressLine, i) => {
                        return (
                          <>
                            {addressLine}
                            <br />
                          </>
                        );
                      })}
                    </p>
                  );

                case "email":
                  return <p>{item.value}</p>;

                default:
                  return (
                    <div className="mainSection pt-4">
                      <h4 className="mainSectionHeader">
                        <span id={`${mainItem.mainSection}`}>{`${index + 1}. ${
                          mainItem.mainSection
                        }`}</span>
                      </h4>
                      <p>{item.text}</p>
                    </div>
                  );
              }
            });

            return element;
          })}
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
