import config from "../../config/config";

// import config from "../../config";
const axios = require("axios");

const { proxy, apiVersion } = config;
const BASE_URL = `${proxy}/${apiVersion}`;

const { privacyPolicy } = require("../../data/privacy-policy");
const { tos } = require("../../data/tos");

export const getPrivacyPolicy = async () => {
	try {
		return privacyPolicy;
		// const res = await axios.get(`${BASE_URL}/legal/privacy-policy`);
		// return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const getTOS = async () => {
	//console.log(`${BASE_URL}/legal/tos`);
	try {
		return tos;
		// const res = await axios.get(`${BASE_URL}/legal/tos`);
		// return res.data;
	} catch (err) {
		console.log(err);
	}
};
