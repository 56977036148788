import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const eyridStoriesData = [
  {
    industry: "Financial Services",
    problem: "Legacy privilege system was difficult to maintain.",
    outcome:
      "Needs assessment and migration to new solution. Custom connector development. Capability Augmentation.",
    location: "Canada",
    icon: icon({ name: "coins" }),
  },
  {
    industry: "Government",
    problem: "Difficulty in managing access to high privilege accounts.",
    outcome: "Configuration review, update, and capability enhancement.",
    location: "Canada",
    icon: icon({ name: "building-columns" }),
  },
  {
    industry: "Private Equity",
    problem:
      "Difficulty in managing load of dynamic IAM environment with privileged users.",
    outcome:
      "Configuration review, simplification of onboarding and termination, capability enhancement, automated reporting and dashboarding.",
    location: "Canada",
    icon: icon({ name: "chart-pie" }),
  },

  {
    industry: "Financial Services",
    problem: "Capability gap in IAM ecosystem.",
    outcome:
      "Solution deployment and transition training for capability enhancement.",
    location: "Canada",
    icon: icon({ name: "coins" }),
  },
  {
    industry: "Power and Utility",
    problem:
      "Difficulty in managing load of dynamic IAM environment and required updates.",
    outcome:
      "Configuration review, update, and capability enhancement. Simplification of operating environment and knowledge transfer.",
    location: "Canada",
    icon: icon({ name: "lightbulb" }),
  },
  {
    industry: "Financial Services",
    problem:
      "Internal infrastructure provisioning was at capabity and a staging environment was required for solution testing.",
    outcome:
      "Staging and Lab Environment deployed via cloud, preloaded with a mirrored environment to internal tooling and testing solutions.",
    location: "Canada",
    icon: icon({ name: "coins" }),
  },
  {
    industry: "Financial Services",
    problem:
      "Capacity and capability gap in meeting upgrade schedules for regulatory requirements.",
    outcome: "Configuration review, update, and capability enhancement.",
    location: "Canada",
    icon: icon({ name: "coins" }),
  },
];
