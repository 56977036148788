import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import data from "../data/glossary.json";
import styled from "styled-components";

// This function will scroll the window to the top
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // for smoothly scrolling
  });
};

const Glossary = () => {
  const [showButton, setShowButton] = useState(false);

  //Use to show or unshow letter scroll bar
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        //set false to hide
        setShowButton(true);
      }
    });
  }, []);

  // console.log(data)
  return (
    <Layout
      hasFooter
      pageTitle={`EyrID: Unified Identity & Access Management`}
      page="eyrid"
    >
      <section style={{ margin: "2em" }}>
        {showButton && (
          <ScrolltoTopFab
            color="primary"
            aria-label="add"
            onClick={scrollToTop}
          >
            {/* <ArrowUpward /> */}
            <LetterBox>
              {data.map((item, index) => {
                return (
                  <div key={index}>
                    {item.words.length > 0 ? (
                      <a href={"/glossary/#" + item.letter}> {item.letter}</a>
                    ) : (
                      <Disabled class="disabled">{item.letter}</Disabled>
                    )}
                  </div>
                );
              })}
            </LetterBox>
          </ScrolltoTopFab>
        )}

        <CenterBox>
          <StyledSection>
            <h1>Glossary</h1>
            <StyledP>
              The cybersecurity industry is full of acronyms, complicated
              technical terms and buzzwords. We’ve compiled this glossary of the
              most common terms and concepts with the aim of making
              cybersecurity easier and accessible for all.
            </StyledP>
          </StyledSection>
          {data.map((item, index) => {
            if (item.words.length > 0) {
              return (
                <StyledSection id={item.letter} key={index}>
                  {<h5>{item.letter}</h5>}
                  {item.words.map((word, index) => {
                    return (
                      <div key={index}>
                        <p class="word">
                          <b>{word.word}</b>
                        </p>
                        <StyledP>{word.def}</StyledP>
                      </div>
                    );
                  })}
                </StyledSection>
              );
            }
          })}
        </CenterBox>
      </section>
    </Layout>
  );
};

export default Glossary;

const StyledSection = styled.section`
  // border: red 1px solid;
  padding: 2rem;
  margin: 1rem;

  h5 {
    padding-bottom: 1.5rem;
  }

  div {
    padding-bottom: 1.5rem;
  }

  .word {
    padding: 0;
    margin: 0;
  }
`;

const LetterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: white;

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    // width: auto;
  }
`;

const Disabled = styled.a`
  pointer-events: none;
  cursor: default;
  color: grey;
`;

const StyledP = styled.p`
  max-width: 65ch;
`;

const CenterBox = styled.div`
  display: grid;
  place-content: center;

  p {
    display: flex;
    max-width: 65ch;
  }
`;

const ScrolltoTopFab = styled.button`
  position: fixed;
  z-index: 500;
  top: 5em;
  box-shadow: 1px 4px 11px 5px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 1em;
  padding: 0.5em;
  // margin: 2em;
  color: white;
  background-color: #141e30;

  @media (max-width: 768px) {
    width: 80vw;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);
  }
`;
