import React from "react";
import SideLines from "../../../assets/images/png/side_lines.png";
import BgShadow from "../../../assets/images/png/bg_shadow.png";
import Flower from "../../../assets/images/svg/flower.svg";
import CyberImg from "../../../assets/images/png/cyber_img.png";
import CyberImg2 from "../../../assets/images/svg/cyber_img2.svg";
import GoToPlatform from "../../button/GoToPlatform";

function Journey() {
  return (
    <section class="mt-5 position-relative ">
      <img
        src={SideLines}
        alt="side_lines"
        class="position-absolute end-0 side_lines index_minus"
      />
      <img
        src={BgShadow}
        alt="bg_shadow"
        class="position-absolute end-0 bg_shadow_sec_5 w-75 index_minus"
      />
      <div class=" overflow-hidden py-5 position-relative custom-vh-100 d-flex align-items-center">
        <div class="container custom_container mt-5">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-xl-5">
              <div class="" data-aos="zoom-in-down" data-aos-duration="1200">
                <h2 class="fw-bold ff_bai fs_5xl text-white mb-2">
                  Begin Your Journey
                </h2>
                <p class="fw-normal ff_bai fs_sm opacity_09 text-white  pe-lg-4">
                  With EyrID to help you in your IAM journey, Identity and
                  Access Management becomes as easy as following the map to your
                  goal!
                </p>
                <ul class="ps-0">
                  <li class="mt-5">
                    <span class="d-inline-block">
                      <GoToPlatform />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-10 col-lg-6 col-xl-7">
              <div class="mt-xxl-5 d-none d-lg-block position-absolute top-50  translate-middle-y cyber_img_w_50">
                <div
                  class="position-relative"
                  data-aos="zoom-in-down"
                  data-aos-duration="1200"
                >
                  <img
                    src={CyberImg}
                    alt="cyber"
                    class="w-100 dashboard_img_border"
                  />
                  <div class="pot_position position-absolute">
                    <img src={Flower} alt="flower" class="" />
                  </div>
                </div>
              </div>
              <img
                src={CyberImg2}
                alt="cyber"
                class="w-100 mt-5 pt-4 d-lg-none"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Journey;
