import React, { useEffect } from "react";

import Footer from "../../components/footer/Footer";
import Navbar2 from "../../components/navigation/Navbar";

const Layout = ({ children, pageTitle, hasFooter }) => {
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <div className="eyrid">
        {/*   This is the nav bar that sticks on the top
        <Navbar2 /> 
        */}
        <section className="align-items-center" style={{ overflow: "hidden" }}>
          {children}
        </section>
        {hasFooter ? <Footer /> : null}
      </div>
    </>
  );
};

export default Layout;
