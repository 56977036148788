import hashicorp from "../assets/images/svg/hashicorp.svg";
import cyberark from "../assets/images/svg/cyberark.svg";
import radiantlogic from "../assets/images/svg/radiant_logic.svg";
import sailpoint from "../assets/images/svg/sailpoint.svg";
import azuread from "../assets/images/svg/azure.svg";
import pingidentity from "../assets/images/svg/ping_identity.svg";

export const eyridCollaboratorsData = [
  {
    company: "CyberArk",
    img_src: cyberark,
    link: "https://www.cyberark.com/",
  },
  {
    company: "SailPoint",
    img_src: sailpoint,
    link: "https://www.sailpoint.com/",
  },
  {
    company: "HashiCorp",
    img_src: hashicorp,
    link: "https://www.hashicorp.com/",
  },
  {
    company: "RadiantLogic",
    img_src: radiantlogic,
    link: "https://www.radiantlogic.com/",
  },
  {
    company: "Microsoft Azure AD",
    img_src: azuread,
    link: "https://azure.microsoft.com/en-ca/services/active-directory/#overview",
  },
  {
    company: "PingIdentity",
    img_src: pingidentity,
    link: "https://www.pingidentity.com/",
  },
];
